* {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

html {
    height: 100%;
}

body {
    font-family: $fontFamily;
    font-size: $fontSize;
    font-weight: 400;
    color: $textColor;
    -webkit-font-smoothing: antialiased;
    padding: 0;
    margin: 0;
    min-height: 100%;
    background-color: $bodyBgColor;
}

a {
    text-decoration: none;
    @include transition(color $transitionDuration);
}

.layout-wrapper {
    .route-bar {
        min-height: 32px;
        background-color: $breadcrumbBgColor;
        border-bottom: $breadcrumbBorder;
    
        .route-bar-breadcrumb {
            padding: 6px 25px;
            display: inline-block;
            ul {
                margin: 0;
                padding: 0;
                list-style: none;
                color: $textSecondaryColor;
                display: inline-block;

                li {
                    margin-right: .25em;
                    list-style-type: none;
                    display: inline-block;
                    color: $textSecondaryColor;
                    text-transform: capitalize;

                    button {
                        color: $textSecondaryColor;
                    }
                }
            }
        }
    }
}

.layout-mask {
    display: none;
    background-color: #252529;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 998;
    width: 100%;
    height: 100%;
    @include opacity(.8);
}
