input.invalid-field, .invalid-field input, body .invalid-field .p-inputtext:enabled:hover, body .invalid-field .p-inputtext:enabled:focus {
    border-color: red;
}

.docs {
    &-container {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
    }

    &-item {
        display: flex;
        flex-direction: row;
        width: 500px;

        .p-button-text {
            padding: 5px 2px
        }

        &__info {
            flex-grow: 1;
            .p-selectbutton {
                display: flex;
                margin-left: 10px;
            }
        }

        &__image {
            flex: 0 0 100px;
            //width: 100px;
            background-position: 50% 50%;
            background-repeat: no-repeat;
            //background-size: contain;
            background-size: cover;
            cursor: pointer;
        }
    }
}


.eba-docs-envelope.docs-container .docs-item-image a {
    margin-right: 0;
}

body .card .eba-docs-envelope .eba-courier-doc-thumb img {
    margin-right: 0;
}
body .eba-courier-avatar-img {
    width: 100%;
}

.p-selectbutton.p-buttonset.select-single-row {
    display: flex;
}

.p-selectbutton.p-buttonset.select-single-row .p-button-text {
    padding: .5em;
}
