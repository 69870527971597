* {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    .p-component {
        font-family: $fontFamily;
        font-size: $fontSize;
        text-decoration: none;
    }

    a {
        color: $linkColor;
        text-decoration: none;

        &:hover {
            color: $linkHoverColor;
        }

        &:active {
            color: $linkActiveColor;
        }
    }

    .p-disabled, .p-component:disabled {
        @include opacity($disabledOpacity);
    }

    .pi {
        font-size: $primeIconFontSize;
    }

    .p-link {
        font-size: $fontSize;
        font-family: $fontFamily;
        background: transparent;
        background-color: transparent;
        @include border-radius($borderRadius);

        &:focus {
            @include focused();
        }
    }

    /* Code Highlight */
    .docs {
        pre[class*="language-"] {
            padding: 0 !important;
            background-color: #fdfdfd;
            border-left: none;
            @include shadow(none);

            > code {
                border-left: 10px solid lighten($primaryColor, 15%);
                box-shadow: none;
                font-size: 14px;
            }
        }
    }
}
