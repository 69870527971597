$primaryLighterColor:#BBDEFB;
$primaryColor:#2196F3;
$primaryDarkColor:#1976D2;
$primaryDarkerColor:#1565C0;
$primaryTextColor:#ffffff;

$accentColor:$primaryColor;
$accentDarkerColor:$primaryDarkColor;
$accentColorText:$primaryTextColor;

$containerHeaderBgColor:#212121;
$containerHeaderBorder:1px solid #212121;
$containerHeaderTextColor:#ffffff;
$containerHeaderIconColor:#BDBDBD;
$containerHeaderIconHoverColor:#ffffff;

$clickableContainerHeaderBgColor:#212121;
$clickableContainerHeaderBorder:1px solid #212121;
$clickableContainerHeaderTextColor:#ffffff;
$clickableContainerHeaderIconColor:#BDBDBD;

$clickableContainerHeaderHoverBgColor:#373737;
$clickableContainerHeaderHoverBorder:1px solid #373737;
$clickableContainerHeaderHoverTextColor:#ffffff;
$clickableContainerHeaderHoverIconColor:#BDBDBD;

$clickableContainerHeaderActiveBgColor:$primaryColor;
$clickableContainerHeaderActiveBorder:1px solid $primaryColor;
$clickableContainerHeaderActiveTextColor:$primaryTextColor;
$clickableContainerHeaderActiveIconColor:$primaryTextColor;

$clickableContainerHeaderActiveHoverBgColor:$primaryDarkColor;
$clickableContainerHeaderActiveHoverHoverBorder:1px solid $primaryDarkColor;
$clickableContainerHeaderActiveHoverTextColor:$primaryTextColor;
$clickableContainerHeaderActiveHoverIconColor:$primaryTextColor;

@import './src/css/theme/_theme';
