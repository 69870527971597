/* Utils */
@keyframes fadeInDown {
	from {
		opacity: 0;
		transform: translate3d(0, -20px, 0);
	}

	to {
		opacity: 1;
		transform: none;
	}
}

@-webkit-keyframes fadeInDown {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, -20px, 0);
		transform: translate3d(0, -20px, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: none;
		transform: none;
	}
}

@-webkit-keyframes fadeOutUp {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
		-webkit-transform: translate3d(0, -20px, 0);
		transform: translate3d(0, -20px, 0);
	}
}

@keyframes fadeOutUp {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
		-webkit-transform: translate3d(0, -20px, 0);
		transform: translate3d(0, -20px, 0);
	}
}

.fadeInDown {
	-webkit-animation-name: fadeInDown;
	animation-name: fadeInDown;
}

.fadeOutUp {
	-webkit-animation-name: fadeOutUp;
	animation-name: fadeOutUp;
}

.clearfix:after {
	content: " ";
	display: block;
	clear: both;
}

.card {
	border-radius: $borderRadius;
	background: $cardBgColor;
	padding: 16px;
	margin-bottom: 16px;
	border: $cardBorder;
	@include border-radius($borderRadius);

	&.card-w-title {
		padding-bottom: 16px;
	}

	&.no-gutter {
		margin-bottom: 0;
	}

	h1 {
		font-size: 20px;
		font-weight: 400;
		margin: 24px 0;

		&:first-child {
			margin-top: 0px;
		}
	}

	h2 {
		font-size: 18px;
		font-weight: 400;
	}

	h3 {
		font-size: 16px;
		font-weight: 400;
	}

	h4 {
		font-size: 14px;
		font-weight: 400;
	}

	hr {
		background-color: #ccc;
		height: 1px;
		border: 0 none;
		margin: 24px 0;
	}

	.eba-card-toolbar {
		margin-bottom: 8px;
		//height: 32px;

		.eba-toolbar-subleft {
			float: left;
			margin-right: 8px;

			h2 {
				margin: 0;
			}
		}

		.eba-toolbar-subright {
			float: right;
		}
	}

	.eba-footer-counter {
		margin-top: 16px;
		font-size: 12px;
		color: #999;
	}

	.eba-list-item-clear {
		list-style: none;
		margin-top: 12px;

		dt {
			color: #666;
			letter-spacing: 1px;
		}

		dd {
			margin-left: 0;
		}
	}

	.eba-list-item-tab {
		list-style: none;
		display: table-row;

		dt {
			color: #666;
			display: table-cell;
			padding-right: 18px;
			padding-bottom: 4px;
		}

		dd {
			display: table-cell;
			font-family: monospace;
		}
	}

	.eba-docs-envelope {
		overflow: hidden;

		.eba-courier-doc-thumb {
			padding: 8px;
			margin: 8px;
			border: 1px solid #ccc;
			overflow: hidden;
			float: left;

			img {
				cursor: pointer;
				float: left;
				margin-right: 16px;
			}

			.eba-doc-info {
				float: left;
			}
		}
	}
}

.nopad {
	padding: 0;

	.p-panel-content {
		padding: 0;
	}
}

.separator {
	border: 1px solid $dividerColor;
}

.p-grid {
	-ms-flex-wrap: wrap;

	.eba-address-th {
		padding: 16px;
		color: #808080;
		letter-spacing: 1px;
	}
}

.layout-wrapper {
	.layout-ajax-loader {
		position: absolute;
		right: 15px;
		bottom: 15px;

		.layout-ajax-loader-icon {
			color: $topbarBgColor;
			font-size: 32px;
		}
	}
}

.p-datatable {
	.eba-id-column {
		width: 78px;
	}

	.p-datatable-tbody {
		tr {
			td.eba-numeric-column {
				font-family: monospace;
				text-align: right;
			}
		}
	}

	.p-datatable-row {
		cursor: pointer;

		&:hover {
			background-color: #ccff99;
		}
	}
}

.eba-metro-label {
	color: #fff;
	padding: 2px 8px;
	border-radius: 8px;
}

.eba-input-changed {
	background: #ffffdd !important;

	.p-spinner-input {
		background: #ffffdd !important;
	}
}

.eba-toggle-input-changed {
	border: 1px solid #000000 !important;
}

.eba-address-list-head {
	margin: 0 !important;
}

.eba-address-list-item {
	border: 1px solid #cccccc;
	border-bottom: 0 none;
	margin: 0 !important;

	.eba-cell-to-show-on-hover {
		display: none;
	}

	.eba-address-cell {
		padding: 16px;
		cursor: pointer;
	}

	.eba-address-list-editor {
		background-color: #cccccc;
		padding: 32px;
		margin: 0;
	}

	&:last-child {
		border-bottom: 1px solid #cccccc;
	}

	&:hover {
		background-color: #f0ffee;

		.eba-cell-to-show-on-hover {
			display: block;
		}
	}
}

.eba-address-list-item-editing {
	border: 1px solid #000000 !important;
	margin: 0 0 16px 0 !important;
	box-shadow: rgba(0,0,0, 0.4) 1px 1px 4px;
}

.eba-courier-avatar-img {
	min-height: 64px;
	min-width: 64px;
	max-height: 400px;
	max-width: 500px;
	border: 1px solid #808080;
}
