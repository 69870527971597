.image-view {
    display: inline-block;
    position: fixed;
    right: 20px;
    top: 50%;
    margin-top: -400px;
    width: 640px;
    height: 800px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-color: black;
    text-align: right;
    z-index: 1000;

    & &__close {
        display: inline-block;
        color: white;
        cursor: pointer;
        font-size: 28px;
    }
    &__img {
        width: 100%;
    }
}
