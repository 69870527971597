$logoBgColor:#FFFFFF;
$topbarBgColor:#FF5722;
$topbarIconColor:#ffffff;
$topbarIconHoverBgColor:#FF8A65;
$lightMenuitemActiveTextColor:#FF5722;
$darkMenuitemActiveTextColor:#FF5722;
$horizontalMenuitemActiveBgColor:#FF5722;
$horizontalMenuitemActiveTextColor:#ffffff;
$badgeBgColor:#FF5722;
$badgeTextColor:#ffffff;

@import './layout/_layout';
