body {
    .p-breadcrumb {
        background-color: $panelContentBgColor;
        border: $panelContentBorder;
        padding: $panelContentPadding;
        @include border-radius($borderRadius);

        ul {
            li {
                .p-menuitem-link {
                    color: $panelContentTextColor;
                    margin: 0;
                    @include transition(box-shadow $transitionDuration);
                    @include border-radius($borderRadius);

                    &:focus {
                        @include focused();
                    }
                }

                &.p-breadcrumb-chevron {
                    margin: 0 $inlineSpacing 0 $inlineSpacing;
                    color: $textSecondaryColor;
                }

                &:first-child {
                    a {
                        color: $textSecondaryColor;
                        margin: 0;
                    }
                }

                .p-menuitem-icon {
                    color: $textSecondaryColor;
                }
            }
        }
    }

    .p-steps {
        position: relative;
        
        .p-steps-item {
            background-color: transparent;
            text-align: center;
            @include opacity(1);
                        
            .p-menuitem-link {
                display: inline-block;
                text-align: center;
                background-color: transparent;
                overflow: hidden;
                
                .p-steps-number {
                    @include border-radius($stepsItemBorderRadius);
                    display: inline-block;
                    color: $stepsItemNumberColor;
                    background-color: $stepsItemBgColor;
                    border: $stepsItemBorder;
                    position: relative;
                    top: $fontSize;
                    margin-bottom: $fontSize;
                    width: $stepsItemWidth;
                    height: $stepsItemHeight;
                    font-size: $fontSize + 2;
                    padding-top: 0.429em;
                    text-align: center;
                }
                
                .p-steps-title {
                    display: block;
                    margin-top: 6px;
                    color: $stepsItemTextColor;
                }

                &:not(.p-disabled):focus {
                    @include focused();
                }
            }
                        
            &.p-highlight {
                .p-steps-number {
                    background: $highlightBgColor;
                    color: $highlightColorText;
                }
                
                .p-steps-title { 
                    font-weight: 700;
                    color: $textColor;
                }
            }
            
            &:last-child {
                .p-menuitem-link {
                    display: block;
                }
            }
        }

        &:before {
            content:' ';
            border-top: $panelContentBorder;
            width: 100%;
            top: 45%;
            left: 0;
            display: block;
            position: absolute;
        }
    }

    .p-menu .p-menuitem-link,
    .p-menubar .p-menuitem-link,
    .p-tieredmenu .p-menuitem-link,
    .p-contextmenu .p-menuitem-link,
    .p-megamenu .p-menuitem-link,
    .p-slidemenu .p-menuitem-link {
        padding: $menuitemPadding;
        color: $menuitemTextColor;
        font-weight: normal;
        @include border-radius(0);
        @include transition(background-color $transitionDuration, box-shadow $transitionDuration);

        .p-menuitem-text {
            color: $menuitemTextColor;
        }

        .p-menuitem-icon {
            color: $menuitemIconColor;
            margin-right: $inlineSpacing;
        }

        &:hover {
            background-color: $menuitemHoverBgColor;

            .p-menuitem-text {
                color: $menuitemHoverTextColor;
            }
    
            .p-menuitem-icon {
                color: $menuitemHoverIconColor;
            }
        }

        &:focus {
            @include focused-listitem();
        }
    }

    .p-menu {
        padding: $menuPadding;
        background-color: $menuBgColor;
        border: $menuBorder;
        @include border-radius($borderRadius);

        .p-menuitem {
            margin: $menuitemMargin;
        }

        &.p-menu-overlay {
            border: $overlayMenuBorder;
            @include shadow($overlayMenuShadow);
        }

        .p-submenu-header {
            margin: $submenuHeaderMargin;
            padding: $menuitemPadding;
            color: $panelHeaderTextColor;
            background-color: $panelHeaderBgColor;
            font-weight: $panelHeaderFontWeight;
            border: 0 none;
            @include border-radius-top($borderRadius);
        }

        .p-menu-separator {
            border: $menuBorder;
            border-width: 1px 0 0 0;
        }
    }

    .p-menubar {
        padding: $menuPadding;
        background-color: $menuBgColor;
        border: $menuBorder;
        @include border-radius($borderRadius);

        .p-menubar-root-list {
            > .p-menuitem {
                > .p-menuitem-link {
                    padding: $menuitemPadding;
                }
            }
        }

        .p-submenu-list {
            padding: $submenuPadding;
            background-color: $menuBgColor;
            @include shadow($overlayMenuShadow);

            .p-menuitem {
                margin: $menuitemMargin;
            }

            .p-menu-separator {
                border: $menuBorder;
                border-width: 1px 0 0 0;
            }
        }

        .p-menuitem {
            margin: $menuitemMargin;
            
            &.p-menuitem-active {
                > .p-menuitem-link {
                    background-color: $menuitemActiveBgColor;

                    .p-menuitem-text {
                        color: $menuitemActiveTextColor;
                    }
            
                    .p-menuitem-icon, .p-submenu-icon {
                        color: $menuitemActiveIconColor;
                    }
                }
            }
        }
    }

    .p-contextmenu {
        padding: $menuPadding;
        background-color: $menuBgColor;
        border: $overlayMenuBorder;
        @include shadow($overlayMenuShadow);
        @include border-radius($borderRadius);

        .p-submenu-list {
            padding: $submenuPadding;
            background-color: $menuBgColor;
            @include shadow($overlayMenuShadow);
        }

        .p-menuitem {
            margin: $menuitemMargin;

            .p-menuitem-link {
                .p-submenu-icon {
                    right: 0.429em;
                }
            }

            &.p-menuitem-active {
                > .p-menuitem-link {
                    background-color: $menuitemActiveBgColor;

                    .p-menuitem-text {
                        color: $menuitemActiveTextColor;
                    }
            
                    .p-menuitem-icon, .p-submenu-icon {
                        color: $menuitemActiveIconColor;
                    }
                }
            }
        }

        .p-menu-separator {
            border: $menuBorder;
            border-width: 1px 0 0 0;
        }
    }

    .p-tieredmenu {
        padding: $menuPadding;
        background-color: $menuBgColor;
        border: $menuBorder;
        @include border-radius($borderRadius);

        .p-submenu-list {
            padding: $submenuPadding;
            background-color: $menuBgColor;
            @include shadow($overlayMenuShadow);
        }

        .p-menuitem {
            margin: $menuitemMargin;

            .p-menuitem-link {
                .p-submenu-icon {
                    right: 0.429em;
                }
            }

            &.p-menuitem-active {
                > .p-menuitem-link {
                    background-color: $menuitemActiveBgColor;

                    .p-menuitem-text {
                        color: $menuitemActiveTextColor;
                    }
            
                    .p-menuitem-icon, .p-submenu-icon {
                        color: $menuitemActiveIconColor;
                    }
                }
            }
        }

        .p-menu-separator {
            border: $menuBorder;
            border-width: 1px 0 0 0;
        }

        &.p-menu-overlay {
            border: $overlayMenuBorder;
            @include shadow($overlayMenuShadow);
        }
    }

    .p-slidemenu {
        padding: $menuPadding;
        background-color: $menuBgColor;
        border: $menuBorder;
        @include border-radius($borderRadius);

        .p-submenu-list {
            padding: $submenuPadding;
            background-color: $menuBgColor;
            border: 0 none;
        }

        .p-menuitem {
            margin: $menuitemMargin;

            .p-menuitem-link {
                .p-submenu-icon {
                    right: 0.429em;
                }
            }

            &.p-menuitem-active {
                > .p-menuitem-link {
                    background-color: $menuitemActiveBgColor;

                    .p-menuitem-text {
                        color: $menuitemActiveTextColor;
                    }
            
                    .p-menuitem-icon, .p-submenu-icon {
                        color: $menuitemActiveIconColor;
                    }
                }
            }
        }

        .p-slidemenu-backward {
            margin: $submenuHeaderMargin;
            padding: $panelHeaderPadding;
            color: $panelHeaderTextColor;
            background-color: $panelHeaderBgColor;
            font-weight: $panelHeaderFontWeight;
            border: 0 none;

            &:hover {
                background-color: $clickablePanelHeaderHoverBgColor;
                color: $clickablePanelHeaderHoverTextColor;
            }
        }

        .p-menu-separator {
            border: $menuBorder;
            border-width: 1px 0 0 0;
        }

        &.p-menu-overlay {
            border: $overlayMenuBorder;
            @include shadow($overlayMenuShadow);
        }
    }

    .p-tabmenu {
        border: 0 none;

        .p-tabmenu-nav {
            padding: 0;
            background: $tabsNavBgColor;
            border-bottom: $panelHeaderBorder;
            @include clearfix();
           
            .p-tabmenuitem {
                .p-menuitem-link {
                    margin-bottom: -1px;
                    border: $clickablePanelHeaderBorder;
                    background-color: $clickablePanelHeaderBgColor;
                    color: $clickablePanelHeaderTextColor;
                    padding: $clickablePanelHeaderPadding;
                    margin-right: $tabHeaderSpacing;
                    @include border-radius-top($borderRadius);
                    @include transition($clickablePanelHeaderTransition);

                    .p-menuitem-text {
                        color: $clickablePanelHeaderTextColor;
                    }

                    .p-menuitem-icon {
                        color: $clickablePanelHeaderIconColor;
                        margin-right: $inlineSpacing;
                    }

                    &:focus {
                        @include focused();
                    }
                }

                &:not(.p-highlight):not(.p-disabled):hover {
                    .p-menuitem-link {
                        background-color: $clickablePanelHeaderHoverBgColor;
                        border: $clickablePanelHeaderHoverBorder;

                        .p-menuitem-text {
                            color: $clickablePanelHeaderHoverTextColor;
                        }

                        .p-menuitem-icon {
                            color: $clickablePanelHeaderHoverIconColor;
                        }
                    }
                }

                &.p-highlight {
                    .p-menuitem-link {
                        background-color: $clickablePanelHeaderActiveBgColor;
                        border: $clickablePanelHeaderActiveBorder;

                        .p-menuitem-text {
                            color: $clickablePanelHeaderActiveTextColor;
                        }

                        .p-menuitem-icon {
                            color: $clickablePanelHeaderActiveIconColor;
                        }
                    }
                }
            }
        }
    }

    .p-megamenu {
        padding: $menuPadding;
        background-color: $menuBgColor;
        border: $menuBorder;
        @include border-radius($borderRadius);

        .p-megamenu-submenu-header {
            margin: $submenuHeaderMargin;
            padding: $panelHeaderPadding;
            color: $panelHeaderTextColor;
            background-color: $panelHeaderBgColor;
            font-weight: $panelHeaderFontWeight;
            border: 0 none;
        }

        .p-megamenu-panel {
            padding: $submenuPadding;
            background-color: $menuBgColor;
            @include shadow($overlayMenuShadow);
        }

        .p-menuitem {
            margin: $menuitemMargin;
        
            .p-menuitem-link {
                .p-submenu-icon {
                    right: 0.429em;
                }
            }

            &.p-menuitem-active {
                > .p-menuitem-link {
                    background-color: $menuitemActiveBgColor;

                    .p-menuitem-text {
                        color: $menuitemActiveTextColor;
                    }
            
                    .p-menuitem-icon, .p-submenu-icon {
                        color: $menuitemActiveIconColor;
                    }
                }
            }
        }

        &.p-megamenu-vertical {
            .p-megamenu-root-list {
                > .p-menuitem {
                    > .p-menuitem-link {
                        > .p-submenu-icon {
                            right: 0.429em;
                        }
                    }
                }
            }
        }
    }

    .p-panelmenu {
        .p-icon {
            position: static;
        }

        .p-panelmenu-panel {
            .p-panelmenu-header {
                margin-top: $accordionSpacing;
            }

            &:first-child {
                .p-panelmenu-header {
                    margin-top: 0;
                }
            }
        }

        .p-panelmenu-header {
            padding: 0;
           
            > a {
                border: $clickablePanelHeaderBorder;
                background-color: $clickablePanelHeaderBgColor;
                color: $clickablePanelHeaderTextColor;
                padding: $clickablePanelHeaderPadding;
                font-weight: $clickablePanelHeaderFontWeight;
                position: static;
                font-size: $fontSize;
                @include border-radius($borderRadius);
                @include transition($clickablePanelHeaderTransition);

                .p-panelmenu-icon {
                    color: $clickablePanelHeaderIconColor;

                    &.pi-caret-right {
                        @include icon_override("\e901");
                    }
        
                    &.pi-caret-down {
                        @include icon_override("\e902");
                    }
                }

                &:focus {
                    @include focused();
                    z-index: 100;
                }
            }

            &:not(.p-highlight) {                
                > a:hover {
                    outline: 0 none;
                    border: $clickablePanelHeaderHoverBorder;
                    background-color: $clickablePanelHeaderHoverBgColor;
                    color: $clickablePanelHeaderHoverTextColor;

                    .p-panelmenu-icon {
                        color: $clickablePanelHeaderHoverIconColor;
                    }
                }
            }

            &.p-highlight {
                margin-bottom: 0;
                > a {
                    border: $clickablePanelHeaderActiveBorder;
                    background-color: $clickablePanelHeaderActiveBgColor;
                    color: $clickablePanelHeaderActiveTextColor;
                    @include border-radius-bottom(0);

                    .p-panelmenu-icon {
                        color: $clickablePanelHeaderActiveIconColor;
                    }

                    &:hover {
                        outline: 0 none;
                        border: $clickablePanelHeaderActiveHoverBorder;
                        background-color: $clickablePanelHeaderActiveHoverBgColor;
                        color: $clickablePanelHeaderActiveHoverTextColor;
        
                        .p-panelmenu-icon {
                            color: $clickablePanelHeaderActiveHoverIconColor;
                        }
                    }
                }
            }
        }

        .p-panelmenu-panel {
            &:first-child {
                margin-top: 0;
            }
        }

        .p-panelmenu-content {
            padding: $menuPadding;
            background-color: $menuBgColor;
            border: $menuBorder;
            margin-top: 0;
            position: static;
            border-top: 0 none;

            .p-menuitem {
                margin: $menuitemMargin;

                .p-menuitem-link {
                    padding: $menuitemPadding;
                    color: $menuitemTextColor;
                    @include transition(background-color $transitionDuration, box-shadow $transitionDuration);
    
                    &:hover {
                        background-color: $menuitemHoverBgColor;
 
                        .p-menuitem-icon, .p-panelmenu-icon {
                            color: $menuitemHoverIconColor;
                        }

                        .p-menuitem-text {
                            color: $menuitemHoverTextColor;
                        }     
                    }

                    &:focus {
                        @include focused-listitem();
                    }
                }
            }
        }
    }
}